<script src="../../i18n/i18n.js"></script>
<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-4/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body">
                    <div class="vx-row">
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
                            <div style="justify-content: center"
                                 class="vx-col social-login-buttons flex flex-wrap items-center">
                            </div>
                            <div class="mt-2" style="display: flex;justify-content: flex-end;">
                                <i18n id="i18n-login"/>
                            </div>

                            <div class="px-8 pt-8 login-tabs-container" >
                                <div class="vx-card__title mb-8" style="display: flex;justify-content: center;">
                                    <img src="../../assets/images/logo/logov2cam.png" style="max-width:300px" />

                                </div>
                                <div class="vx-card__title mb-2" style="justify-content: center;" id="form-login">
                                    <div class="text-center text-muted text-slogan"><b>{{$t('login.titleLogin')}}</b>
                                        <!--                                    <div class="text-center text-muted text-slogan"><b>Giải pháp quản lí nhân sự hàng-->
                                        <!--                                        đầu</b>-->
                                    </div>
                                    <div class="txt-login mt-5">
                                        <vs-input
                                            v-validate="'required|min:3'"
                                            data-vv-validate-on="blur"
                                            type="account"
                                            name="account"
                                            icon-no-border
                                            icon="icon icon-user"
                                            icon-pack="feather"
                                            :label-placeholder="$t('login.account')"
                                            v-model="username"
                                            autocomplete="off"
                                            v-on:keydown.enter="loginAction"
                                            class="w-full px-4"
                                            id="text-account"/>
                                        <span class="text-danger text-sm px-8">{{
                                                errors.first('account')
                                            }}</span>
                                    </div>
                                    <div class="txt-login">
                                        <vs-button v-if="activeIconEye" type="flat" class="float-right eye-icon" icon="icon-eye"
                                            icon-pack="feather" icon-no-border button-no-border @click="HiddenShowPassWord"
                                            style="margin-left: -68px; margin-right: 40px;"></vs-button>
                                        <vs-button v-if="activeIconEyeOff" type="flat" class="float-right eye-icon" icon="icon-eye-off"
                                            icon-pack="feather" icon-no-border @click="HiddenShowPassWord"
                                            style="margin-left: -68px; margin-right: 30px;"></vs-button>
                                        <vs-input
                                            data-vv-validate-on="blur"
                                            v-validate="'required|min:6'"
                                            :type="typeOption"
                                            name="password"
                                            icon="icon icon-lock"
                                            icon-pack="feather"
                                            label-placeholder="Mật khẩu"
                                            v-model="password"
                                            v-on:keydown.enter="loginAction"
                                            class="w-full px-4"
                                            id="text-password"/>
                                        <span
                                            icon="icon icon-lock"
                                            icon-pack="feather"></span>
                                        <span class="text-danger text-sm px-8">{{
                                                errors.first('password')
                                            }}</span>
                                    </div>

                                    <div class="flex flex-wrap mb-5">
                                        <div class="md:w-1/2 w-full">
                                            <div class="txt-rmb flex ml-8 w-full">
                                                <vs-checkbox v-model="remember"></vs-checkbox>
                                                <b>{{$t('login.rememberLogin')}}</b>
                                            </div>
                                        </div>
                                        <div class="cursor-pointer md:w-1/2 w-full text-right">
                                            <span class="cursor-pointer mr-8 mb-3 text-primary" style="font-size:13px" @click="$router.push('/pages/forgot-password')">{{ $t('login.forgotPassword') }}</span>
                                        </div>
                                    </div>
                                    <div class="txt-login px-4 mb-10">
                                        <vs-button class="w-full login-button" :disabled="!validateForm"
                                                   @click="loginAction">
                                            <b style="color: white">{{$t('login.logIn')}}</b>
                                        </vs-button>
                                    </div>

                                    <router-link class="text-bottom-t mx-3 mb-10"
                                                 style="display: flex;justify-content: center;"
                                                 to="/pages/register"><b>{{$t('login.noAcc')}}</b>
                                        <span class="registerNow ml-2"><b> {{$t('login.register')}}</b></span>
                                    </router-link>
                                    <div class="hr-sect">{{$t('login.or')}}</div>

                                    <div style="justify-content: center"
                                         class="vx-col social-login-buttons flex flex-wrap items-center">
                                        <div class="vx-row bg-google pt-3 pb-2 px-6 rounded-lg cursor-pointer"
                                             v-google-signin-button="clientId">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                                 data-icon="google"
                                                 class="text-white h-4 w-4 svg-inline--fa fa-google fa-w-16" role="img"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                                                <path fill="currentColor"
                                                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                         <div class="vx-col hidden sm:w-full md:w-full lg:block lg:w-1/2 full-page-bg-color size-background">
                            <!--                          <img src="@/assets/images/pages/login/BackGLogin.png" alt="login" class="mx-auto auto-image">-->
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script src="https://apis.google.com/js/platform.js" async defer></script>
<script>

import GoogleSignInButton from 'vue-google-signin-button-directive';
import I18n from "../../layouts/components/navbar/components/I18n";
const CryptoJS = require("crypto-js");
const key = "U2FsdGVkX19ApQZG0Fa966qeGqPr1a6De5OJusStuaI";

export default {
    data() {
        return {
            clientId: '652595998415-vj3g0qcd2emiq7ktvmnkk1qba7qoock8.apps.googleusercontent.com'    ,
            remember: false,
            username: null,
            password: null,
            activeIconEye: true,
            activeIconEyeOff: false,
            typeOption: 'password',
        }
    },
    directives: {
        GoogleSignInButton,
    },
    components: {
        I18n
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    created() {
        this.getCookie()
    },
    methods: {
        encryptAES(data, key) {
            return CryptoJS.AES.encrypt(data, key).toString();
        },
        decryptAES(data, key){
            const decrypted = CryptoJS.AES.decrypt(data, key);
            if (decrypted) {
            try {
                const str = decrypted.toString(CryptoJS.enc.Utf8);
                if (str.length > 0) {
                return str;
                } else {
                return 'error 1';
                }
            } catch (e) {
                return 'error 2';
            }
            }
            return 'error 3';
        },

        setCookie(username, password, exdays) {
            if (password.length > 0) password = this.encryptAES(password, key);
            let exdate = new Date();
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
            document.cookie = "username".concat("=").concat(username).concat(";path=/;exprires=").concat(exdate.toDateString());
            document.cookie = "userPassword".concat("=").concat(password).concat(";patch=/;exprires=").concat(exdate.toDateString());
        },

        getCookie(){
            if (document.cookie.length > 0) {
                let arr = document.cookie.split(";");
                for(let i=0; i< arr.length;i++){
                    let arrInfo = arr[i].split("=");
                    if (arrInfo[0].trim() == "username") {
                        this.remember = true;
                        this.username = arrInfo[1];
                        this.remember = arrInfo[1].length > 0 ? true : false;
                    }
                    else if (arrInfo[0].trim() == 'userPassword') {
                        if (arrInfo[1].length > 0) {
                            this.password = this.decryptAES(arrInfo[1], key);}
                        this.remember = arrInfo[1].length > 0 ? true : false;
                    }
                }
            }
        },
        clearCookie(){
            this.setCookie("", "",-1);
        },

        OnGoogleAuthSuccess(idToken) {
            this.$vs.loading();
            this.$oauth.post('/registration/google', {token: idToken}).then((response) => {
                if (response.data) {
                    this.$store.dispatch('auth/login', {
                        username: response.data.email,
                        password: response.data.uuidLogin
                    })
                        .then(() => {
                            this.$vs.loading.close();
                            location.reload();
                        })
                        .catch(error => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                text: error.response.data.error_description || this.$t('login.errServer'),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        });
                }
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        OnGoogleAuthFail(error) {
        },
        registerUser() {
        },
        loginAction() {
            this.$vs.loading();
            if (this.remember) {
                this.setCookie(this.username, this.password, 365);
            } else {
                this.clearCookie();
            }
            this.$store.dispatch('auth/login', {username: this.username, password: this.password}).then(() => {
                this.$vs.loading.close();
                this.$eventBus.$emit("login-success", {});

            })
            .catch(error => {
                this.$vs.loading.close();
                this.$vs.notify({
                    text: error.response.data.error_description || this.$t('login.errServer'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            });
        },
        HiddenShowPassWord() {
            this.activeIconEyeOff = !this.activeIconEyeOff
            if (this.typeOption == 'password') {
                this.typeOption = 'text'
            } else {
                this.typeOption = 'password'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
#i18n-login {
    color: rgba(var(--vs-primary),1) !important;
}

.registerNow {
    color: rgba(var(--vs-primary),1) !important;
}

.login-button {
    border-radius: 36px;
}

.login-button:hover {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    animation: hover-jump .3s ease-in-out;
}

@keyframes hover-jump {
    from {
        top: 0px;
    }
    to {
        bottom: 2px;
    }
}

.text-center {
    color: rgba(var(--vs-primary),1) !important;
    font-size: 15.6px;
}

.text-bottom-t {
    color: #8e9196 !important;
}

.txt-rmb {
    color: #8e9196 !important;
    opacity: unset !important;
}

.text-danger {
    text-align: center !important;
}

.txt-login {
    left: auto;
}

.vx-card {
    border-radius: 36px;
}

.d-theme-dark-bg {
    background-color: #fff;
    border-radius: 25px 0 0 25px;
}

.layout--full-page .full-page-bg-color {
    background-image: url("../../assets/images/pages/login/backgroud-login.png");
    border-radius: 0px 26px 26px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pLeftErr {
    padding-left: 135px !important;
    padding-right: 7px !important;
}

.px-4 {
    padding-left: 125px !important;
    padding-right: 125px !important;
}

@media only screen and (min-width: 10px) {
    .px-4 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .pLeftErr {
        padding-left: 3rem !important;
        padding-right: 2rem !important;
    }
}

@media only screen and (max-width: 992px) {
    .d-theme-dark-bg {
        border-radius: 25px;
    }
}

@media only screen and (min-width: 1366px) {
    .pLeftErr {
        padding-left: 60px !important;
        padding-right: 7px !important;
    }
    // .px-4 {
    //     padding-left: 50px !important;
    //     padding-right: 50px !important;
    // }
}

@media only screen and (min-width: 1600px) {
    .pLeftErr {
        padding-left: 135px !important;
        padding-right: 7px !important;
    }
    // .px-4 {
    //     padding-left: 125px !important;
    //     padding-right: 125px !important;
    // }
}

.login-tabs-container {
    margin-top: 60px;
    margin-bottom: 60px;
}

.text-slogan {
    margin-bottom: 3rem;
}

.bg-google {
    background-color: #ff000094
}

.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
}

.hr-sect:before,
.hr-sect:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}
</style>

